<template>
  <span>
  <span class="glyphicon glyphicon-play jpm-play cursor-pointer row-play-pause" v-show="!this.url" @click=play />
   <div v-show="this.url" style="display:flex;align-items:center;"><span title="Ускоренное воспроизведение">2x <input type="checkbox" @change=speed v-model="rate"></span>&nbsp;<audio controls style="width:100%" preload="none" autoplay="autoplay" :src=url ></audio></div>
  </span>
</template>

<script>

import darsan from "darsan"

export default {
  
  name: "Audio",
  
  props: {
    record: String,
  },
  
  data()
  {
    return {
      url: null,
      rate: false
    }
  },
  
  methods: {
    speed(){
      this.$el.getElementsByTagName('audio')[0].playbackRate = ( this.rate ) ? 2 : 1 ;
    },
    async play()
    {
      const rec = await darsan.promiseDarsanToken2()
      const p = '?darsan2=' + encodeURIComponent(rec.token)
      if (rec.pretend) p += "&darsan-pretend=" + rec.pretend
      this.url = darsan.makeUrl('','phone', '/record/' + this.record + p)
    },
  },
}
</script>