<template>
  <Input :mask=maskaOpt :size=size :labelSize=labelSize :name=name :label=label :info=info
    :apiDomain=apiDomain apiTopic=:apiTopic :apiPath=apiPath :modelValue=modelValue @update:modelValue="$emit('update:modelValue', $event)"
  </Input>
</template>

<script>
import Input from "common/visual/cooper/Input.vue"
import PropsMixin from "common/visual/cooper/PropsMixin.vue"
import { h } from 'vue'

export default {
  name: "IpAddress",

  components: {Input},
  mixins: [PropsMixin],

  data()
  {
    return {
      maskaOpt: {
        mask: "#00.#00.#00.#00",
        tokens: {
          0: { pattern: /[0-9]/, optional: true }
        },
      },
    }
  },

}
</script>
