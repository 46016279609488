<template>
  <Input :mask=maskaOpt :size=size :labelSize=labelSize :name=name :label=label :info=info
    :apiDomain=apiDomain apiTopic=:apiTopic :apiPath=apiPath :modelValue=modelValue @update:modelValue="$emit('update:modelValue', $event)"
  </Input>
</template>

<script>
import Input from "common/visual/cooper/Input.vue"
import PropsMixin from "common/visual/cooper/PropsMixin.vue"

export default {
  name: "Ident",

  components: {Input},
  mixins: [PropsMixin],

  data()
  {
    return {
      maskaOpt: {
        mask: "wW",
        tokens: {
          w: { pattern: /[_a-z]/i },
          W: { pattern: /[-_0-9a-z]/i, repeated: true }
        },
      },
    }
  },

}
</script>
