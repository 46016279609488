<template>
  <div/>
<!--
<% var labelXS = o.lxs ? 'col-xs-' + o.lxs : 'col-xs-3' %>
<% var inputXS = o.ixs ? 'col-xs-' + o.ixs : 'col-xs-8' %>
<div class="form-group has-feedback">
  <% if (o.label) { %>
    <div class="col-sm-<%=o.l%> <%= labelXS %> text-right">
      <label for="<%=o.name%>" class="control-label"><%=o.label%></label>
    </div>
  <% } %>

  <div class="col-sm-<%=o.i%> <%= inputXS %>">
    <input type='text' class='form-control text-cuper' name="<%=o.name%>" id="<%=o.name%>" value="<%=o.model[o.name]%>"
      <%if (!o.perm.get('billing.root') || o.ip) {%>readonly1<%}%> >
    <span class="help-<%=o.name%> help-block hidden"></span>
  </div>

  <span class="input-addon">
    <div class="btn-group allocate-ip">
      <button class="btn btn-default auto-allocate-ip" type="button">
        <span title="Выделить IP" class="glyphicon glyphicon-plus" style="cursor:pointer"></span>
      </button>

      <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="caret"></span>
        <span class="sr-only">Из пула</span>
      </button>

      <ul class="dropdown-menu">
        <% if (o.pools) { o.pools.each(el => { %>
          <% if (!el.get("has_free_ip")) return %>

          <li class="allocate-from-pool">
            <a href="#">
              <span data-pool="<%= el.get("id") %>" <% if (!el.get("gray")) {%> class="text-success" <% } %>><%= el.get("ip") %></span>
            </a>
          </li>
        <% }); } %>
      </ul>

    </div>
      <span title="Очистить IP" class="glyphicon glyphicon-remove clear-ip" style="display: none; cursor:pointer"></span>
  </span>
</div>
-->
</template>

<script>
export default {
  name: "IpChooser",
  
}
</script>

<style scoped>
</style>
