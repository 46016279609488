<template>
  <Input :mask=maskaOpt :size=size :labelSize=labelSize :name=name :label=label :info=info
    :apiDomain=apiDomain apiTopic=:apiTopic :apiPath=apiPath :modelValue=modelValue @update:modelValue="$emit('update:modelValue', $event)"
  </Input>
</template>

<script>
import Input from "common/visual/cooper/Input.vue"
import PropsMixin from "common/visual/cooper/PropsMixin.vue"

export default {
  name: "Phone",
  
  components: {Input},
  mixins: [PropsMixin],

  data() 
  {
    return {
      maskaOpt: {
        eager: true,
        mask: modelValue => this.undecorateValue(modelValue).startsWith("9") ? "+7 (###) ###-##-##" : "(###) ###-##-##",
      },
    }
  },
  
  methods: {
    undecorateValue(value)
    {
      return value.replace(/^\+7 /,"").replace(/\D/g,"")
    }
  },

}
</script>
