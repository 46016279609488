<template>
</template>

<script>

export default {
  
  props: {
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    size: {
      type: Number,
      default: 6,
    },
    modelValue: {
       type: [Number, String, Array, Boolean],
    },
    labelSize: {
      type: Number,
      default: 3,
    },
    validate: Function,
    apiDomain: {
      type: String,
      default: "",
    },
    apiTopic: {
      type: String,
    },
    apiPath: {
      type: String,
    },
    save: {
      type: [Function, Promise],
    },
    info: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    // Дополнительные параметры для идентификации контрола
    extra: {
      type: [String, Number, Object],
    },
  },
}
</script>
