<template>
  <div class="form-group has-feedback" :class="{'has-error': error}" style="margin-bottom: 0px">
    <label :for=uniqueId class="control-label" :class="'col-sm-'+labelSize">{{label}}</label>
    <div :class="'col-sm-'+size">
      <input class="form-control" :class="{title: asTitle}" autocomplete="off" :name=name :id=uniqueId :value=localValue :maxlength=maxlength :disabled=disabled
      @change=newValue @focus=savePrevious v-maska:[mask] />

      <Icon v-show="has_undo" name=fa-reply class="form-control-feedback opacity-half-full"
      style="cursor: pointer; pointer-events: auto;" @click.native=undo />

      <span class="help-block">{{error}}</span>
    </div>

    <Icon v-if="info" name="fa-info-circle" :title=info />
    <slot></slot>

  </div>
</template>

<script>
import CooperMixin from "common/visual/cooper/CooperMixin.vue"
import { vMaska } from "maska"

export default {
  name: "Input",
  
  mixins: [CooperMixin],
  directives: { maska: vMaska },
    
  data() 
  {
    return {
      element: "input",
    }
  },
  
  props: {
    maxlength: {
      type: [String,Number],
      default: null,
    },
    asTitle: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: Object,
      default: () => null,
    },
    
  },

}
</script>

<style scoped>

.title:not(:focus)
{ 
  border: none;
  outline: none;
  box-shadow: none;
  font-weight: bold;
  background: lightgray;
}
</style>
